@font-face {
  font-family: 'PingFang Heavy';
  src: url('../../public/fonts/PingFang Heavy.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.dialogue {
  position: relative;
  z-index: 1;
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  background: #000000;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    width: 60vw;
    height: 60vw;
    background: linear-gradient(180deg, rgba(211, 45, 159, 0.69) 0%, rgba(97, 49, 251, 0.69) 100%);
    border-radius: 50%;
    filter: blur(26vw);
    right: 10.4vw;
    top: 2.71vh;
    z-index: 0;
  }

  &::after {
    content: '';
    position: absolute;
    width: 85vw;
    height: 85vw;
    background: linear-gradient(180deg, rgba(211, 45, 159, 0.69) 0%, rgba(97, 49, 251, 0.69) 100%);
    border-radius: 50%;
    filter: blur(26vw);
    left: -42.13vw;
    bottom: 4.31vh;
    z-index: 0;
  }

  .header {
    height: 54px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    position: relative;
    z-index: 2;

    .back {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img {
        width: 18px;
        height: 18px;
      }
    }

    .title {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      font-family: 'PingFang Heavy', sans-serif;
      font-size: 17px;
      color: #C5C5D1;
    }

    .search {
      margin-left: auto;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img {
        width: 18px;
        height: 18px;
      }
    }
  }

  .list {
    flex: 1;
    width: 100%;
    height: 716px;
    overflow-y: auto;
    border-radius: 12px;
    background: rgba(22, 22, 34, 0.6);
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    box-shadow: 
      0 8px 16px rgba(0, 0, 0, 0.08),
      0 4px 8px rgba(0, 0, 0, 0.06),
      inset 0 -1px 0 rgba(0, 0, 0, 0.1);

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(22, 22, 34, 0.3);
      border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(215, 45, 156, 0.5);
      border-radius: 2px;
    }

    .item {
      height: 80px;
      min-height: 80px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      padding: 0 16px;
      cursor: pointer;
      display: flex;
      align-items: center;

      .l {
        display: flex;
        align-items: flex-start;
        width: 100%;
        padding: 14px 0;

        .photo {
          flex-shrink: 0;
          width: 52px;
          height: 52px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
          }
        }

        .msgBox {
          flex: 1;
          margin-left: 16px;
          display: flex;
          flex-direction: column;
          height: 52px;
          padding-top: 6px;
          min-width: 0;

          .name {
            color: #C5C5D1;
            font-size: 15px;
            font-family: 'PingFang SC';
            font-weight: 400;
            word-wrap: break-word;
            height: 22px;
            line-height: 22px;
            margin-bottom: 4px;
          }

          .msg {
            color: #686872;
            font-size: 12px;
            font-family: 'PingFang SC';
            font-weight: 400;
            word-wrap: break-word;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            height: 18px;
            line-height: 18px;
          }
        }
      }
    }
  }
}
