@import './fonts.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'PingFang', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

html {
  background: #2e0e36;
}