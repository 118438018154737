.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);

  .modal-content {
    background: rgba(30, 30, 30, 0.95);
    border-radius: 16px;
    width: 90%;
    max-width: 600px;
    max-height: 80vh;
    overflow-y: auto;
    position: relative;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.1);

    .modal-header {
      padding: 20px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: sticky;
      top: 0;
      background: rgba(30, 30, 30, 0.95);
      z-index: 1;

      .tabs {
        display: flex;
        gap: 16px;
        
        .tab {
          background: none;
          border: none;
          color: rgba(255, 255, 255, 0.6);
          font-size: 16px;
          cursor: pointer;
          padding: 6px 12px;
          border-radius: 4px;
          transition: all 0.3s ease;
          
          &:hover {
            color: #fff;
          }
          
          &.active {
            color: #fff;
            background-color: rgba(94, 50, 254, 0.2);
            font-weight: 500;
          }
        }
      }

      .close-button {
        background: none;
        border: none;
        color: rgba(255, 255, 255, 0.6);
        font-size: 24px;
        cursor: pointer;
        padding: 0;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        transition: all 0.3s ease;

        &:hover {
          background: rgba(255, 255, 255, 0.1);
          color: #fff;
        }
      }
    }

    .modal-body {
      padding: 20px;
      color: rgba(255, 255, 255, 0.9);
      font-size: 14px;
      line-height: 1.6;
      max-height: calc(80vh - 80px);
      overflow-y: auto;
      scrollbar-width: thin;
      -ms-overflow-style: -ms-autohiding-scrollbar;

      h1 {
        color: #fff;
        margin: 0 0 20px;
        font-size: 22px;
        font-weight: 600;
      }

      h2 {
        color: #fff;
        margin: 24px 0 16px;
        font-size: 18px;
        font-weight: 500;

        &:first-child {
          margin-top: 0;
        }
      }

      h3 {
        color: #fff;
        margin: 20px 0 10px;
        font-size: 16px;
        font-weight: 500;
      }

      p {
        margin: 10px 0;
        color: rgba(255, 255, 255, 0.8);
      }

      ul, ol {
        margin: 10px 0;
        padding-left: 20px;

        li {
          margin: 5px 0;
          color: rgba(255, 255, 255, 0.8);
        }
      }

      a {
        color: #5e32fe;
        text-decoration: none;
        
        &:hover {
          text-decoration: underline;
        }
      }

      hr {
        border: none;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        margin: 20px 0;
      }

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 3px;
      }
    }

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
      display: none;
    }
  }
}