.chatDetail {
  position: relative;
  height: 100vh;
  width: 100vw;
  background-color: #000000;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  touch-action: none; // 禁用所有默认触摸行为

  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 64px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    z-index: 10;
    touch-action: none;
    user-select: none;
  }

  .dotStatus {
    position: fixed;
    top: 48px;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
    padding: 4px 12px;
    border-radius: 12px;
    font-size: 12px;
    z-index: 10;
    touch-action: none;
    user-select: none;
  }

  video {
    touch-action: none;
    pointer-events: none;
    user-select: none;
    object-fit: cover;
  }

  .messageList {
    position: fixed;
    top: 50vh;
    left: 0;
    right: 0;
    bottom: 64px;
    overflow-y: auto;
    padding: 16px;
    -webkit-overflow-scrolling: touch;
    touch-action: pan-y;
    overscroll-behavior: contain;
    scroll-behavior: smooth;

    // 隐藏滚动条
    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;  /* Firefox */
    -ms-overflow-style: none;  /* IE and Edge */

    .messageSeparator {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 16px 0;
      padding: 0 20px;
      
      img {
        width: 100%;
        max-width: 375px;
        height: 2px;
        opacity: 1.0;
      }
    }

    .messageItem {
      display: flex;
      align-items: flex-start;
      margin-bottom: 20px;
      position: relative;

      .checkbox-container {
        position: relative;
        cursor: pointer;
        padding-left: 25px;
        margin-top: 3px;
        margin-right: 8px;

        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 18px;
          width: 18px;
          background-color: #563481;
          border-radius: 4px;

          &:after {
            content: "";
            position: absolute;
            display: none;
            left: 6px;
            top: 3px;
            width: 4px;
            height: 8px;
            border: solid #A75FB4;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
          }
        }

        input:checked ~ .checkmark:after {
          display: block;
        }
      }

      .messageContent {
        max-width: 70%;
        padding: 10px 15px;
        border-radius: 10px;
        position: relative;
        backdrop-filter: blur(3px) !important;
        -webkit-backdrop-filter: blur(4px) !important;
        color: white !important;
        font-size: 16px !important;
        font-family: 'PingFang', -apple-system, BlinkMacSystemFont, 'PingFang SC', 'Microsoft YaHei', sans-serif !important;
        font-weight: 400 !important;
        word-wrap: break-word;
        line-height: 1.5 !important;
        white-space: pre-wrap;
        display: flex;
        flex-direction: column;
      }

      &.left {
        justify-content: flex-start;
        .messageContent {
          background: linear-gradient(90deg, rgba(40, 4, 58, 0.50) 0%, rgba(9, 4, 58, 0.50) 100%) !important;
          margin-right: auto;
        }
      }

      &.right {
        justify-content: flex-end;
        .messageContent {
          background: linear-gradient(90deg, rgba(4, 22.90, 58, 0.50) 0%, rgba(5.80, 4, 58, 0.50) 100%) !important;
          margin-left: auto;
        }
      }

      input[type="checkbox"] {
        margin: 8px;
        width: 18px;
        height: 18px;
      }

      .audioWaves {
        position: relative;
        margin-top: 8px;
        display: flex;
        align-items: flex-end;
        height: 20px;
        gap: 2px;
        align-self: flex-start;
        cursor: pointer;

        img {
          width: 20px;
          height: 20px;
        }

        &.playing {
          .wave {
            width: 1px;
            margin-right: 3px;
            background: linear-gradient(180deg, #dd2898 0%, #5eb9f8 100%);
            transition: height 0.2s ease-in-out, transform 0.2s ease-in-out, background 0.2s ease-in-out;
            height: 20px;

            @for $i from 1 through 5 {
              &.wave-#{$i} {
                animation: audio-wave 2s ease-in-out infinite;
                animation-delay: #{$i * 0.1}s;
              }
            }
          }
        }
      }

      @keyframes audio-wave {
        0% {
          transform: scaleY(0.2);
        }
        50% {
          transform: scaleY(0.7);
        }
        100% {
          transform: scaleY(0.2);
        }
      }
    }
  }

  .theaterPanel {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(30, 30, 30, 0.95);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    padding: 16px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    transform: translateY(100%);
    transition: transform 0.3s ease;
    z-index: 1000;

    &.show {
      transform: translateY(0);
    }

    .selectedCount {
      color: #C5C5D1;
      font-size: 14px;
      font-family: 'PingFang Medium';
    }

    .buttonGroup {
      display: flex;
      gap: 12px;
      align-items: center;
    }

    .cancelButton {
      padding: 8px 16px;
      border-radius: 20px;
      color: #C5C5D1;
      font-size: 14px;
      font-family: 'PingFang Medium';
      cursor: pointer;
      border: 1px solid rgba(255, 255, 255, 0.2);
      background: transparent;
      transition: all 0.3s ease;

      &:hover {
        border-color: rgba(255, 255, 255, 0.3);
        color: #fff;
      }
    }

    .generateButton {
      background: linear-gradient(90deg, #5E32FE 0%, #D92D9B 100%);
      padding: 8px 16px;
      border-radius: 20px;
      color: white;
      font-size: 14px;
      font-family: 'PingFang Medium';
      cursor: pointer;
      border: none;
      
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

  .inputArea {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(22, 22, 34, 0.9);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    padding: 12px 16px;
    transition: all 0.3s ease-out;
    // height: 64px;
    overflow: hidden;
    touch-action: none;
    user-select: none;
    z-index: 2;
    border-radius: 12px 12px 0 0;

    .tips {
      width: 100%;
      padding: 0;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      // justify-content: space-between;
      opacity: 0;
      transform: translateY(100%);
      transition: all 0.3s ease-out;
      display: none;

      .action {
        width: 62px;
        height: 27px;
        padding: 0 17px;
        background: linear-gradient(180deg, rgba(157, 52, 212, 0.33) 0%, rgba(107, 29, 85, 0.33) 100%);
        border-radius: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgba(255, 255, 255, 0.8);
        font-size: 14px;
      }

      .tipIcon {
        cursor: pointer;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 12px;
        img {
          width: 24px;
          height: 24px;
        }
      }
    }

    .inputWrapper {
      display: flex;
      align-items: center;
      gap: 12px;
      position: relative;
      height: 40px;

      .addButton {
        position: relative;
        width: 30px;
        height: 30px;
        cursor: pointer;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        
        &::before {
          content: '';
          position: absolute;
          top: -15px;
          left: -15px;
          right: -15px;
          bottom: -15px;
        }
        
        img {
          width: 30px;
          height: 30px;
          transition: transform 0.3s ease;
          
          &.rotated {
            transform: rotate(-45deg);
          }
        }
      }

      .inputBox {
        flex: 1;
        height: 40px;
        display: flex;
        align-items: center;
        background: #282B39;
        border-radius: 20px;
        padding: 0 16px;

        input {
          flex: 1;
          border: none;
          outline: none;
          font-size: 15px;
          background: transparent;
          color: #fff;
          height: 100%;
          line-height: 40px;

          &::placeholder {
            color: rgba(255, 255, 255, 0.5);
          }
        }
      }

      .sendButton {
        position: relative;
        width: 30px;
        height: 30px;
        cursor: pointer;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        
        &::before {
          content: '';
          position: absolute;
          top: -15px;
          left: -15px;
          right: -15px;
          bottom: -15px;
        }
        
        img {
          width: 30px;
          height: 30px;
        }
      }
    }

    .menuPanel {
      margin-top: 16px;
      grid-template-columns: repeat(4, 1fr);
      gap: 16px;
      opacity: 0;
      transform: translateY(100%);
      transition: all 0.3s ease-out;
      display: none;
      animation: collapseMenu 100ms linear;

      .menuItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        cursor: pointer;

        img {
          width: 32px;
          height: 32px;
        }

        span {
          font-size: 12px;
          color: #333;
        }

        &:active {
          opacity: 0.7;
        }
      }

      &.showMenu {
        animation: expandMenu 100ms linear;
      }
    }

    &.expanded {
      // height: 150px;
      // padding-bottom: calc(8px + env(safe-area-inset-bottom));
      .menuPanel {
        opacity: 1;
        transform: translateY(0);
        display: grid;
      }
    }

    &.is-input-focus {
      // height: 150px;
      // padding-bottom: calc(8px + env(safe-area-inset-bottom));

      .tips {
        display: inline-flex;
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  .menuOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    animation: fadeIn 0.2s ease-out;

    .menuContent {
      background: #fff;
      border-radius: 16px 16px 0 0;
      padding: 20px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 16px;
      animation: slideUp 0.3s ease-out;

      .menuItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        cursor: pointer;

        img {
          width: 24px;
          height: 24px;
        }

        span {
          font-size: 12px;
          color: #333;
        }

        &:active {
          opacity: 0.7;
        }
      }
    }
  }

  .settingsSidebar {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 1000;
    display: flex;
    
    .sidebarOverlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.3);
    }

    .sidebarContent {
      position: relative;
      width: 80%;
      max-width: 300px;
      margin-left: auto;
      height: 100%;
      background: rgba(28, 28, 32, 0.5);
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(20px);
      padding: 20px;
      z-index: 1;
      overflow-y: auto;
      animation: slideIn 0.3s ease-out;

      .sectionTitle {
        font-size: 16px;
        font-weight: 500;
        color: #fff;
        margin: 20px 0 16px;
        
        &:first-child {
          margin-top: 0;
        }
      }

      .formBox {
        .formItem {
          margin-bottom: 16px;

          label {
            display: block;
            color: rgba(255, 255, 255, 0.8);
            margin-bottom: 8px;
          }

          input[type="text"] {
            width: 100%;
            padding: 8px 12px;
            border: 1px solid rgba(255, 255, 255, 0.2);
            border-radius: 4px;
            font-size: 14px;
            background: rgba(255, 255, 255, 0.1);
            color: #fff;

            &::placeholder {
              color: rgba(255, 255, 255, 0.4);
            }
          }

          .radioGroup {
            display: flex;
            gap: 16px;

            label {
              display: flex;
              align-items: center;
              gap: 8px;
              cursor: pointer;
              color: rgba(255, 255, 255, 0.8);

              input[type="radio"] {
                appearance: none;
                -webkit-appearance: none;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                margin: 0;
                background: rgba(86, 52, 129, 0.8);
                position: relative;
                cursor: pointer;

                &:checked {
                  background: rgba(86, 52, 129, 0.8);

                  &::after {
                    content: '';
                    position: absolute;
                    left: 6px;
                    top: 3px;
                    width: 4px;
                    height: 8px;
                    border: solid rgba(167, 95, 180, 0.7);
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg);
                  }
                }
              }
            }
          }
        }
      }

      .settingItem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;

        span {
          color: rgba(255, 255, 255, 0.8);
        }

        .switch {
          position: relative;
          display: inline-block;
          width: 40px;
          height: 20px;

          input {
            opacity: 0;
            width: 0;
            height: 0;

            &:checked + .slider {
              background: rgba(167, 95, 180, 0.7);
            }

            &:checked + .slider:before {
              transform: translateX(20px);
            }
          }

          .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: #C5C5D1;
            transition: .3s;
            border-radius: 20px;

            &:before {
              position: absolute;
              content: "";
              height: 16px;
              width: 16px;
              left: 2px;
              bottom: 2px;
              background-color: white;
              transition: .3s;
              border-radius: 50%;
            }
          }
        }
      }

      .saveButton {
        width: 100%;
        height: 44px;
        border: none;
        border-radius: 22px;
        background: linear-gradient(90deg, #5E32FE 0%, #D92D9B 100%);
        color: #fff;
        font-size: 16px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;

        &:disabled {
          opacity: 0.7;
          cursor: not-allowed;
        }

        span {
          margin-left: 8px;
        }
      }

      .detailButton {
        width: 100%;
        height: 44px;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 22px;
        border: none;
        color: #fff;
        font-size: 16px;
        margin-top: 12px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease;

        span {
          margin-right: 4px;
        }

        .arrow-right {
          width: 16px;
          height: 16px;
          fill: currentColor;
          margin-left: 4px;
        }

        &:hover {
          background: rgba(255, 255, 255, 0.15);
        }

        &:active {
          background: rgba(255, 255, 255, 0.2);
        }
      }
    }
  }

  .title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-family: 'PingFang';
    font-size: 17px;
    color: #FFFF;
  }

  .tipsPanel {
    padding: 0 0 12px 0;
    .tipsTitle {
      display: inline-flex;
      color: #C5C5D1;
      font-size: 14px;
      margin-bottom: 12px;
    }
    .tipItem {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      color: #fff;
      padding: 12px;
      margin-left: -12px;
      width: calc(100% + 24px);
      border-radius: 8px;
      &:hover {
        background-color: #563481;
      }
    }
  } 

  .bracketed-text {
    color: #999;
    font-style: italic;
  }

  @font-face {
    font-family: 'PingFang';
    src: url('../../public/fonts/PingFang Heavy.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
  }

  @keyframes waveAnimation {
    0%, 100% {
      transform: scaleY(0.5);
    }
    50% {
      transform: scaleY(1);
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes slideUp {
    from {
      transform: translateY(20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes slideIn {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }

  @keyframes expandMenu {
    from {
      height: 0px;
    }
    to {
      height: 60px;
    }
  }

  @keyframes collapseMenu {
    from {
      height: 60px;
    }
    to {
      height: 0px;
    }
  }
}
