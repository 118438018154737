.editUser {
    min-height: 100vh;
    background: #1B1436;
    color: #fff;
    padding: 0 20px;

    .navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 0;
        
        .back {
            padding: 8px;
            cursor: pointer;

            img {
                width: 16px;
                height: 16px;
            }
        }

        .title {
            font-size: 18px;
            font-weight: 500;
        }

        .placeholder {
            width: 32px;
            height: 32px;
        }
    }

    .photo {
        width: 75px;
        height: 75px;
        margin: 20px auto 0;
        cursor: pointer;

        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
        }
    }

    .userName {
        text-align: center;
        margin-top: 10px;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.8);
    }

    .formBox {
        margin-top: 30px;

        .formItem {
            display: flex;
            align-items: center;
            padding: 15px 0;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);

            label {
                width: 70px;
                font-size: 14px;
                color: rgba(255, 255, 255, 0.8);
            }

            .custom-input {
                flex: 1;
                height: 44px;
                background-color: #563481;
                border: none;
                border-radius: 8px;
                padding: 0 16px;
                color: #fff;
                font-size: 15px;

                &::placeholder {
                    color: rgba(255, 255, 255, 0.5);
                }

                &:focus {
                    outline: none;
                    box-shadow: 0 0 0 2px rgba(94, 50, 254, 0.3);
                }

                &:disabled {
                    opacity: 0.7;
                    cursor: not-allowed;
                }
            }
        }
    }

    .gradient-button {
        width: 100%;
        height: 44px;
        border: none;
        border-radius: 22px;
        background: linear-gradient(90deg, #5E32FE 0%, #D92D9B 100%);
        color: #fff;
        font-size: 16px;
        cursor: pointer;
        transition: opacity 0.3s;
        margin-top: 40px;

        &:disabled {
            opacity: 0.7;
            cursor: not-allowed;
        }

        &:hover:not(:disabled) {
            opacity: 0.9;
        }
    }
}
