@mixin mobile {
  @media screen and (max-width:660px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

@mixin laptop {
  @media screen and (max-width: 2000px) {
    @content;
  }
}

// Dark theme variables
:root {
  --bg-primary: #121212;
  --bg-secondary: #1e1e1e;
  --bg-tertiary: #2d2d2d;
  --text-primary: #ffffff;
  --text-secondary: #b3b3b3;
  --accent-color: #991111;
  --accent-color-hover: #ffffff;
  --border-color: #333333;
  --card-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
  --gradient-primary: linear-gradient(90deg, #7633ab 0%, #e41919 50%, #e99c2f 100%);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// Login and register form
.formContainer {
  background-color: var(--bg-primary);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .formWrapper {
    background-color: var(--bg-secondary);
    padding: 40px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    min-width: 320px;
    max-width: 400px;
    width: 90%;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);

    .logo {
      width: 180px;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      
      .log {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }

    .title {
      color: var(--text-primary);
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 20px;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;

      input {
        padding: 12px 16px;
        border: 1px solid var(--border-color);
        border-radius: 12px;
        width: 100%;
        background-color: var(--bg-tertiary);
        color: var(--text-primary);
        font-size: 16px;
        transition: all 0.3s ease;

        &:focus {
          border-color: #e63140;
          outline: none;
        }

        &::placeholder {
          color: var(--text-secondary);
        }
      }

      button {
        background: var(--gradient-primary);
        border: none;
        color: var(--text-primary);
        padding: 12px 24px;
        font-weight: 600;
        border-radius: 12px;
        cursor: pointer;
        width: 100%;
        font-size: 16px;
        transition: all 0.3s ease;
        margin-top: 10px;

        &:hover {
          opacity: 0.9;
          transform: translateY(-1px);
        }

        &:active {
          transform: translateY(0);
        }
      }

      label {
        display: flex;
        align-items: center;
        gap: 10px;
        color: var(--text-secondary);
        font-size: 14px;
        cursor: pointer;

        img {
          width: 32px;
        }
      }
    }

    p {
      color: var(--text-secondary);
      font-size: 14px;
      text-align: center;

      a {
        color: var(--accent-color);
        text-decoration: none;
        font-weight: 500;
        
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

// Browse page
.browse-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--bg-primary);

  .browse-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background: linear-gradient(
      to bottom,
      rgba(50, 50, 50, 0.3) 0%,
      #121212 100%
    );
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);

    .tabs-container {
      display: flex;
      gap: 10px;
      overflow-x: auto;
      flex-grow: 1;
      align-items: center;
      padding-right: 16px;
      
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }

      .tab {
        padding: 6px 12px;
        border: 1px solid transparent;
        border-radius: 16px;
        background: linear-gradient(var(--bg-primary), var(--bg-primary)) padding-box,
                var(--gradient-primary) border-box;
        color: var(--text-secondary);
        font-size: 14px;
        white-space: nowrap;
        transition: all 0.3s ease;

        &:hover {
          background: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)) padding-box,
                  var(--gradient-primary) border-box;
        }

        &.active {
          background-image: var(--gradient-primary);
          background-size: 100%;
          color: var(--text-primary);
          border: none;
        }
      }

      .fa-search {
        font-size: 18px;
        color: var(--text-color);
        padding: 8px;
        cursor: pointer;
        transition: all 0.2s;
        margin-left: auto;

        &:hover {
          opacity: 0.8;
          transform: scale(1.1);
        }

        &:active {
          transform: scale(0.95);
        }
      }
    }

    .search-button {
      grid-column: 3;
      justify-self: end;
      width: 50px;
      height: 40px;
      border: none;
      background: transparent;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      color: var(--text-secondary);
      font-size: 18px;
      transition: color 0.3s ease;

      &:hover {
        color: var(--text-primary);
      }

      &::before {
        content: '';
        width: 20px;
        height: 20px;
        background-image: url('img/search.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }

  .ava-card {
    position: relative;
    margin: 20px;
    background: var(--bg-secondary);
    border-radius: 15px;
    overflow: visible;
    box-shadow: var(--card-shadow);
    transition: transform 0.3s ease;
    
    &:hover {
      transform: translateY(-2px);
    }

    .ava-image {
      height: 400px;
      background: url('https://ava-public.oss-cn-shenzhen.aliyuncs.com/ava_preview/20241204224226.jpg');
      background-size: cover;
      background-position: center;
      padding: 20px;
      color: var(--text-primary);
      border-radius: 20px 20px 0 0;

      .ava-name {
        font-size: 24px;
        margin-bottom: 5px;
        text-shadow: 0 2px 4px rgba(0,0,0,0.5);
      }

      .ava-subtitle {
        font-size: 14px;
        opacity: 0.9;
        text-shadow: 0 2px 4px rgba(0,0,0,0.5);
      }
    }

    .ava-info {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      background: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
      border-radius: 20px 20px 0 0;
      padding: 20px;
      margin-top: -60px;  
      min-height: 120px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
      opacity: 0.5;
      z-index: 100;

      .ava-description {
        color: #ffffff !important;
        line-height: 1.6;
        margin: 0;
        font-size: 16px;
        font-style: italic;
        flex-grow: 1;
        display: flex;
        align-items: flex-end;
        padding-bottom: 10px;
      }

      .ava-intro {
        margin-top: 5px;
        padding: 10px 0;

        h3 {
          color: #ffffff !important;
          margin-bottom: 6px;
          font-size: 16px;
          font-weight: 600;
          padding: 0;
        }

        p {
          color: #ffffff !important;
          line-height: 1.5;
          margin: 0;
          font-size: 14px;
          padding: 0;
        }
      }
    }

    .align-image-button {
      position: absolute;
      right: -10px;
      top: 380px;
      height: 40px;
      background: var(--gradient-primary);
      color: #ffffff;
      padding: 0 16px;
      border: none;
      border-radius: 8px;
      letter-spacing: 1px;  /* 增加文字间距 */
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.3s ease;
      line-height: 14px;
      z-index: 101;
    }
  }
  .bottom-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--bg-primary);
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 15px 0;  
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;

    .nav-button {
      padding: 10px 20px;
      border: none;
      background: none;
      color: var(--text-secondary);
      font-size: 14px;
      transition: color 0.3s ease;
      letter-spacing: 2px;  /* 增加文字间距 */

      &:hover {
        color: var(--accent-color-hover);
      }

      &.active {
        color: #e63140;
        font-weight: 500;
      }
    }
  }
}

// Chat page
.chat-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: var(--bg-primary);
  position: relative;
  overflow: hidden;

  .browse-nav {
    display: grid;
    grid-template-columns: 50px 1fr 50px;
    align-items: center;
    padding: 15px;
    background: linear-gradient(
      to bottom,
      rgba(50, 50, 50, 0.3) 0%,
      #121212 100%
    );
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);

    .title {
      grid-column: 2;
      font-size: 18px;
      font-weight: 500;
      color: var(--text-primary);
      text-align: center;
      letter-spacing: 1px;
      margin: 0 auto;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    .search-button {
      grid-column: 3;
      justify-self: end;
      width: 50px;
      height: 40px;
      border: none;
      background: transparent;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      color: var(--text-secondary);
      font-size: 18px;
      transition: color 0.3s ease;

      &:hover {
        color: var(--text-primary);
      }

      &::before {
        content: '';
        width: 20px;
        height: 20px;
        background-image: url('img/search.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }

  .chat-list {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
    background-color: var(--bg-primary);

    .chat-item {
      padding: 10px;
      cursor: pointer;
      border-bottom: 1px solid var(--border-color);
      transition: background-color 0.2s ease;

      &:hover {
        background-color: var(--bg-secondary);
      }

      .chat-content {
        display: flex;
        align-items: center;
        gap: 10px;

        .avatar {
          position: relative;
          width: 40px;
          height: 40px;
          flex-shrink: 0;

          img {
            width: 100%;
            height: 100%;
            border-radius: 4px;
            object-fit: cover;
          }

          .online-indicator {
            position: absolute;
            bottom: 2px;
            right: 2px;
            width: 8px;
            height: 8px;
            background-color: #4CAF50;
            border-radius: 50%;
            border: 2px solid var(--bg-primary);
          }
        }

        .chat-info {
          flex: 1;
          min-width: 0;

          .chat-name {
            font-weight: 540;
            color: var(--text-primary);
            margin-bottom: 4px;
          }

          .last-message {
            font-size: 12px;
            color: var(--text-secondary);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  .bottom-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--bg-primary);
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 15px 0;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;

    .nav-button {
      padding: 10px 20px;
      border: none;
      background: none;
      color: var(--text-secondary);
      font-size: 14px;
      transition: color 0.3s ease;
      letter-spacing: 2px;

      &:hover {
        color: var(--accent-color-hover);
      }

      &.active {
        color: #e63140;
        font-weight: 500;
      }
    }
  }
}

// ChatRoom styles
.chatroom-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #000000;
  position: relative;
  overflow: hidden;

  .background-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 0;
    overflow: hidden;
    background-attachment: fixed;

    .background-image-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      z-index: 1;
    }

    .background-image-container {
      position: relative;
      width: 100%;
      height: 100vh;

      .background-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        object-fit: cover;
        z-index: 1;
      }

      .background-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        object-fit: cover;
        z-index: 2;
      }

      .background-mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        object-fit: cover;
        z-index: 3;
        mix-blend-mode: multiply;
        opacity: 1;
      }
    }

    .background-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background: rgba(0, 0, 0, 0.3);
      z-index: 3;
    }
  }

  .chatroom-header,
  .messages-container,
  .input-container {
    position: relative;
    z-index: 3;
  }

  .chatroom-header {
    display: flex;
    align-items: center;
    padding: 16px;
    background: rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;

    .back-button {
      position: absolute;
      left: 16px;
      background: none;
      border: none;
      color: white;
      cursor: pointer;
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      .back-icon {
        width: 20px;
        height: 20px;
        filter: brightness(0) invert(1);
      }

      &:hover {
        opacity: 0.8;
      }
    }

    .ava-info {
      flex: 1;
      text-align: center;
      color: white;

      .ava-name {
        font-size: 18px;
        font-weight: 500;
      }

      .ava-status {
        color: #4CAF50;
        font-size: 12px;
      }
    }
  }

  .messages-container {
    flex: 1;
    overflow-y: auto;
    padding: 80px 16px 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
    z-index: 1;
    background: transparent;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.2);
      border-radius: 3px;
    }

    .message {
      display: flex;
      align-items: flex-start;
      gap: 12px;
      max-width: 80%;

      &.user-message {
        margin-left: auto;
        flex-direction: row-reverse;

        .message-content {
          background: rgba(255, 105, 180, 0.15);
          backdrop-filter: blur(8px);
          -webkit-backdrop-filter: blur(8px);
          color: white;
          border-radius: 18px 18px 4px 18px;
          border: 1px solid rgba(255, 105, 180, 0.3);
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
        }
      }

      &.ava-message {
        margin-right: auto;

        .message-content {
          background: rgba(255, 255, 255, 0.08);
          backdrop-filter: blur(8px);
          -webkit-backdrop-filter: blur(8px);
          color: white;
          border-radius: 4px 18px 18px 18px;
          border: 1px solid rgba(255, 255, 255, 0.1);
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
        }
      }

      .message-avatar {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        object-fit: cover;
        border: 2px solid rgba(255, 255, 255, 0.2);
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
      }

      .message-content {
        padding: 12px 16px;
        font-size: 15px;
        line-height: 1.4;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);

        p {
          margin: 0;
          word-break: break-word;
        }

        .message-time {
          font-size: 12px;
          opacity: 0.7;
          margin-top: 4px;
          display: block;
        }

        .audio-message {
          padding: 0;
          background: transparent;
          line-height: 0;

          img {
            display: block;
          }
          
          &:hover {
            background: transparent;
          }
        }
      }
    }
  }

  .input-area {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
    background: rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    display: flex;
    flex-direction: column;
    gap: 12px;
    z-index: 10;

    .input-row {
      display: flex;
      gap: 12px;
      align-items: center;
    }

    .plus-button {
      width: 26px;
      height: 26px;
      border-radius: 13px;
      background: transparent;
      border: none;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.2s;
      padding: 0;

      &:hover {
        opacity: 0.9;
        transform: scale(1.05);
      }

      &:active {
        transform: scale(0.95);
      }
    }

    .input-container {
      flex: 1;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 20px;
      padding: 8px 16px;
      display: flex;
      align-items: center;
      gap: 8px;

      .message-input {
        flex: 1;
        background: transparent;
        border: none;
        color: white;
        font-size: 15px;
        min-width: 100px;

        &::placeholder {
          color: rgba(255, 255, 255, 0.5);
        }

        &:focus {
          outline: none;
        }
      }

      .send-button {
        width: 26px;
        height: 26px;
        border-radius: 13px;
        background: transparent;
        border: none;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.2s;
        padding: 0;

        &:hover {
          opacity: 0.9;
          transform: scale(1.05);
        }
      }
    }

    .action-buttons {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 4px 0;
      gap: 12px;

      .action-btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: transparent;
        border: none;
        color: white;
        cursor: pointer;
        padding: 4px 8px;
        border-radius: 8px;
        transition: all 0.2s;
        font-size: 12px;

        &:hover {
          background: rgba(255, 255, 255, 0.1);
        }

        span {
          margin-top: 4px;
        }
      }
    }
  }
}

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-primary);
  z-index: 1000;

  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 3px solid var(--text-secondary);
    border-top-color: var(--accent-color);
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }

  .loading-text {
    margin-top: 20px;
    color: var(--text-primary);
    font-size: 16px;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes audioWave {
  0%, 100% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(0.5);
  }
}

// Utils
.log {
  width: 180px;
  height: auto;
  object-fit: contain;
}

.er {
  color: #6A947F;
  font-size: 14px;
  text-align: center;
}
.info {
  color: white;
  font-size: 14px;
  text-align: center;
}

.link {
  color: #6A947F;
}

// 情况脚本页面的样式
.circum-page {
  min-height: 100vh;
  background-color: var(--bg-primary);
  display: flex;
  flex-direction: column;

  .circum-header {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(to bottom, rgba(50, 50, 50, 0.3) 0%, var(--bg-primary) 100%);
    position: relative;

    .back-btn {
      background: transparent;
      border: none;
      color: white;
      font-size: 20px;
      padding: 8px;
      cursor: pointer;
      z-index: 1;

      &:hover {
        opacity: 0.8;
      }
    }

    h1 {
      color: white;
      font-size: 18px;
      margin: 0;
      font-weight: 500;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    .right-placeholder {
      width: 40px;
      z-index: 1;
    }
  }

  .tab-container {
    display: flex;
    padding: 16px;
    gap: 16px;
    margin-top: 8px;

    .tab {
      display: flex;
      align-items: center;
      gap: 8px;
      color: var(--text-secondary);
      font-size: 15px;
      padding: 8px 0;
      cursor: pointer;
      position: relative;

      &.active {
        color: white;

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background: white;
          border-radius: 1px;
        }
      }

      .tab-icon {
        width: 24px;
        height: 24px;
        border-radius: 12px;
        background: rgba(255, 255, 255, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;

        &.pink {
          background: linear-gradient(45deg, #ff69b4, #ff1493);
        }
      }
    }

    .login-style-btn {
      background: var(--gradient-primary);
      border: none;
      color: var(--text-primary);
      padding: 8px 16px;
      font-weight: 600;
      border-radius: 12px;
      cursor: pointer;
      width: auto;
      font-size: 15px;
      transition: all 0.3s ease;

      &:hover {
        opacity: 0.9;
        transform: translateY(-1px);
      }

      &:active {
        transform: translateY(0);
      }

      &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
      }
    }
  }

  .script-container {
    flex: 1;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .script-input {
      flex: 1;
      min-height: 200px;
      background: rgba(255, 255, 255, 0.05);
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 16px;
      padding: 16px;
      color: white;
      font-size: 15px;
      resize: none;
      outline: none;

      &::placeholder {
        color: var(--text-secondary);
      }

      &:focus {
        border-color: rgba(255, 255, 255, 0.2);
      }
    }

    .example-text {
      color: var(--text-secondary);
      font-size: 14px;
      padding: 0 8px;
    }
  }

  .submit-btn {
    margin: 16px;
    padding: 16px;
    background: linear-gradient(45deg, #ff69b4, #ff1493);
    border: none;
    border-radius: 12px;
    color: white;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.9;
    }
  }
}

// 事件页面的样式
.event-page {
  min-height: 100vh;
  background-color: var(--bg-primary);
  display: flex;
  flex-direction: column;

  .event-header {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(to bottom, rgba(50, 50, 50, 0.3) 0%, var(--bg-primary) 100%);

    .back-btn {
      background: transparent;
      border: none;
      color: white;
      font-size: 20px;
      padding: 8px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.8;
      }
    }

    h1 {
      color: white;
      font-size: 18px;
      margin: 0;
      font-weight: 500;
    }

    .right-placeholder {
      width: 40px;
    }
  }

  .event-content {
    flex: 1;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .event-section {
      .section-header {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 16px;

        h2 {
          color: white;
          font-size: 16px;
          margin: 0;
          font-weight: 500;
        }

        .help-icon {
          color: var(--text-secondary);
          font-size: 16px;
        }
      }

      .section-description {
        color: var(--text-secondary);
        font-size: 14px;
        line-height: 1.6;
        margin-bottom: 24px;
        white-space: pre-line;
      }

      .event-list {
        .event-item {
          background: rgba(255, 255, 255, 0.05);
          border-radius: 12px;
          padding: 16px;
          margin-bottom: 16px;

          .event-text {
            display: block;
            color: #ff69b4;
            font-size: 15px;
            margin-bottom: 8px;

            &:last-child {
              color: white;
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .add-event-btn {
      margin-top: auto;
      padding: 16px;
      background: var(--gradient-primary);
      border: none;
      border-radius: 12px;
      color: white;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      cursor: pointer;
      transition: opacity 0.2s;

      i {
        font-size: 20px;
      }

      &:hover {
        opacity: 0.9;
      }
    }
  }
}

// My page styles
.my-container {
  min-height: 100vh;
  background-color: var(--bg-primary);
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  .my-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: var(--bg-primary);

    h1 {
      color: var(--text-primary);
      font-size: 18px;
      font-weight: 500;
      margin: 0;
    }

    .feedback-btn {
      color: #e63140;
      background: none;
      border: none;
      font-size: 14px;
      cursor: pointer;
    }
  }

  .profile-section {
    padding: 20px;
    display: flex;
    align-items: center;
    gap: 15px;
    position: relative;

    .avatar-container {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      overflow: hidden;
      
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .user-info {
      flex: 1;

      h2 {
        color: var(--text-primary);
        font-size: 16px;
        margin: 0 0 10px 0;
      }

      .edit-profile-btn {
        background: none;
        border: none;
        color: var(--text-secondary);
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 0;
        cursor: pointer;

        i {
          font-size: 12px;
        }
      }
    }

    .logout-btn {
      position: absolute;
      right: 20px;
      padding: 8px 16px;
      border: none;
      background: transparent;
      color: var(--text-secondary);
      cursor: pointer;
      font-size: 14px;
      transition: color 0.3s ease;

      &:hover {
        color: var(--text-primary);
      }
    }
  }

  .vip-section {
    margin: 20px;
    background-color: var(--bg-secondary);
    border-radius: 12px;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .vip-info {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .vip-tag {
        color: var(--text-primary);
        font-weight: 500;
      }

      .vip-duration {
        color: var(--text-secondary);
        font-size: 14px;
      }
    }

    .vip-btn {
      background: none;
      border: none;
      color: #e63140;
      font-size: 14px;
      cursor: pointer;
    }
  }

  .wallet-section {
    margin: 20px;
    background-color: var(--bg-secondary);
    border-radius: 12px;
    padding: 15px;

    .wallet-header {
      color: var(--text-primary);
      font-size: 16px;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .diamond-balance {
      display: flex;
      align-items: center;
      gap: 8px;
      color: var(--text-primary);
      font-size: 24px;
      margin-bottom: 10px;

      i {
        color: #e63140;
      }
    }

    .wallet-description {
      color: var(--text-secondary);
      font-size: 14px;
      line-height: 1.5;
      margin: 0;
    }
  }

  .bottom-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--bg-primary);
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 15px 0;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;

    .nav-button {
      padding: 10px 20px;
      border: none;
      background: none;
      color: var(--text-secondary);
      font-size: 14px;
      transition: color 0.3s ease;
      letter-spacing: 2px;

      &:hover {
        color: var(--accent-color-hover);
      }

      &.active {
        color: #e63140;
        font-weight: 500;
      }
    }
  }
}

// Scrollbar styling
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: var(--bg-secondary);
}

::-webkit-scrollbar-thumb {
  background: var(--border-color);
  border-radius: 4px;
  
  &:hover {
    background: var(--accent-color);
  }
}