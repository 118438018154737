@font-face {
  font-family: 'PingFang';
  src: url('../../public/fonts/PingFang Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'PingFang';
  src: url('../../public/fonts/PingFang Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'PingFang';
  src: url('../../public/fonts/PingFang Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'PingFang';
  src: url('../../public/fonts/PingFang Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'PingFang';
  src: url('../../public/fonts/PingFang Heavy.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'PingFang';
  src: url('../../public/fonts/PingFang ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
