@font-face {
  font-family: 'PingFang Regular';
  src: url('../../public/fonts/PingFang Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'PingFang Medium';
  src: url('../../public/fonts/PingFang Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

.content {
  height: 100vh; /* 回退值 */
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #000000;
  color: rgba(255, 255, 255, 0.8);
  touch-action: pan-y;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;

  &::before {
    content: '';
    position: absolute;
    width: 60vw;
    height: 60vw;
    background: linear-gradient(180deg, rgba(211, 45, 159, 0.69) 0%, rgba(97, 49, 251, 0.69) 100%);
    border-radius: 50%;
    filter: blur(26vw);
    right: 10.4vw;
    top: 2.71vh;
    z-index: 0;
  }

  &::after {
    content: '';
    position: absolute;
    width: 85vw;
    height: 85vw;
    background: linear-gradient(180deg, rgba(211, 45, 159, 0.69) 0%, rgba(97, 49, 251, 0.69) 100%);
    border-radius: 50%;
    filter: blur(26vw);
    left: -42.13vw;
    bottom: 4.31vh;
    z-index: 0;
  }

  .header {
    padding: 0 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    z-index: 1;
    flex-shrink: 0;
    position: relative;

    .search {
      margin-left: auto;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img {
        width: 18px;
        height: 18px;
      }
    }

    .shousuolan {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      position: relative;
      box-sizing: border-box;
      font-size: 14.5px;
      font-family: 'PingFang Medium', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      scrollbar-width: none;
      height: 46px;
      align-items: center;
      padding: 0 2px;
      &::-webkit-scrollbar {
        display: none;
      }

      .tab {
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(255, 255, 255, 0.08);
        color: rgba(255, 255, 255, 0.7);
        border-radius: 16px;
        flex-shrink: 0;
        margin: 0 6px;
        padding: 0 12px;
        height: 28px;
        cursor: pointer;
        transition: all 0.3s ease;
        letter-spacing: 2px;
        font-weight: 400;
        font-family: 'PingFang Medium', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);

        &:first-child {
          margin-left: 2px;
        }

        &:last-child {
          margin-right: 2px;
        }

        &:hover {
          background: rgba(255, 255, 255, 0.12);
          color: rgba(255, 255, 255, 0.9);
        }

        &.tabActive {
          background: linear-gradient(145deg, #dd2898 0%, #5eb9f8 100%);
          color: #fff;
          font-family: 'PingFang Medium', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
          box-shadow: 0 2px 8px rgba(221, 40, 152, 0.3);
        }
      }
    }
  }

  .content-scroll {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    
    &::-webkit-scrollbar {
      display: none;
    }

    .masonry-grid {
      display: flex;
      margin-left: -5px;
      width: auto;
    }

    .masonry-grid_column {
      padding-left: 5px;
      background-clip: padding-box;

      > div {
        margin-bottom: 5px;
      }
    }
  }

  .cardUser {
    width: 100%;
    display: inline-block;
    z-index: 1;
    border-radius: 20px;
    padding: 10px;
    box-sizing: border-box;
    position: relative;
    margin-bottom: -50px;

    .bgc {
      height: 400px;
      min-height: 400px;
      max-height: 400px;
      border-radius: 20px 20px 0 0;
      overflow: hidden;
      background: rgba(0, 0, 0, 0.2);
      position: relative;

      video {
        border-radius: 20px 20px 0 0;
        overflow: hidden;
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
      }
    }

    .info {
      position: relative;
      border-radius: 0 0 20px 20px;
      color: #fff;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
      overflow: hidden;
    }
    .infotop {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px 15px;
      display: block;
      background: linear-gradient(90deg, rgba(40, 4, 58, 0.5) 0%, rgba(9, 4, 58, 0.5) 100%);
      backdrop-filter: blur(2px);
      width: 100%;
      z-index: 2;
      border-radius: 20px 20px 0 0;
      color: #fff;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);

      .infotopzuo {
        width: 100%;

        .title {
          font-size: 17px;
          text-align: left;
          width: 100%;
        }

        .tags-container {
          display: flex;
          margin-top: 10px;
          width: 100%;
          align-items: flex-start;

          .tags {
            width: 80%;
            display: flex;
            flex-wrap: wrap;
            font-size: 14px;
            padding-right: 10px;

            .tag {
              margin-right: 10px;
              margin-bottom: 5px;
              color: #686872;
              font-family: 'PingFang SC';
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }

          .greeting {
            width: 20%;
            min-width: 80px;
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;

            .btn {
              color: rgba(255, 255, 255, 0.8);
              background: linear-gradient(145deg, #dd2898 0%, #5eb9f8 100%);
              padding: 5px 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 16px;
              font-size: 13px;
              cursor: pointer;
              white-space: nowrap;
              min-width: 70px;
              height: 32px;
            }
          }
        }
      }
    }

    .infobottom {
      padding: 10px;
      background-color: rgba(255, 255, 255, 0.2);

      .title1 {
        font-size: 17px;
        font-weight: bold;
      }

      .desc {
        width: 100%;
        font-size: 13px;
        text-indent: 2em;
        color: rgba(255, 255, 255, 0.8);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.5;
        max-height: calc(1.5em * 2);
      }

      .more {
        color: #d72d9c;
        font-size: 14px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }

    .name {
      font-size: 19px;
      font-weight: bold;
      color: #fff;
      position: absolute;
      top: 25px;
      left: 25px;
      z-index: 2;

      p {
        margin-bottom: 10px;
      }

      .lianjie {
        color: #686872;
        font-family: 'PingFang SC';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .status {
      position: absolute;
      right: 30px;
      top: 30px;
      z-index: 2;
      font-size: 13px;
      background: linear-gradient(90deg, #dd2898 0%, #5eb9f8 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .bottom-nav {
    flex-shrink: 0;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: rgba(30, 30, 30, 0.1);
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    padding: 12px 0;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1;

    .nav-button {
      padding: 8px 24px;
      border: none;
      background: none;
      color: rgba(255, 255, 255, 0.6);
      font-size: 14px;
      cursor: pointer;
      transition: color 0.3s ease;

      &.active {
        color: #dd2898;
        font-weight: 500;
      }

      &:hover {
        color: #dd2898;
      }
    }
  }
}
