@font-face {
  font-family: 'PingFang Regular';
  src: url('../../public/fonts/PingFang Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.condition {
  width: 100%;
  height: 100vh;
  background: #000000;
  display: flex;
  flex-direction: column;
  padding: 40rpx;
  padding-bottom: 90rpx;
  position: relative;
  overflow: hidden;
  color: rgba(255, 255, 255, 0.8);

  &::before {
    content: '';
    position: absolute;
    width: 60vw;
    height: 60vw;
    background: linear-gradient(180deg, rgba(211, 45, 159, 0.69) 0%, rgba(97, 49, 251, 0.69) 100%);
    border-radius: 50%;
    filter: blur(26vw);
    right: 10.4vw;
    top: 2.71vh;
    z-index: 0;
  }

  &::after {
    content: '';
    position: absolute;
    width: 85vw;
    height: 85vw;
    background: linear-gradient(180deg, rgba(211, 45, 159, 0.69) 0%, rgba(97, 49, 251, 0.69) 100%);
    border-radius: 50%;
    filter: blur(26vw);
    left: -42.13vw;
    bottom: 4.31vh;
    z-index: 0;
  }

  .header {
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    position: relative;
    z-index: 2;

    .back {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      img {
        width: 18px;
        height: 18px;
      }
    }

    .title {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      font-size: 17px;
      color: #C5C5D1;
    }

    .placeholder {
      width: 24px;
      height: 24px;
    }
  }

  .desc {
    color: #fff;
    font-size: 14px;
    font-family: 'PingFang Regular';
    font-weight: 400;
    word-wrap: break-word;
    margin: 15px 0 7px 22px;
    position: relative;
    z-index: 3;
  }

  .content {
    width: calc(100% - 44px);
    margin: 10px 22px 0;
    height: calc(100vh - 250px);
    border-radius: 30px;
    position: relative;
    overflow: hidden;
    z-index: 3;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);

    .text-content {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.50);
      z-index: 2;
      display: flex;
      flex-direction: column;
      align-items: stretch;

      .divider {
        margin: 15px 0;
        display: flex;
        justify-content: center;
        height: 1px;
        line-height: 0;
      }

      .line {
        align-self: center;
        width: 116px;
        height: 1px;
        background: rgba(255, 255, 255, 0.10);
      }

      .contxt {
        width: 100%;
        min-height: 109px;
        height: auto;
        position: relative;
        z-index: 3;
        background: linear-gradient(180deg, rgba(15, 2, 22, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 26px 18px 15px;
        margin: 0;

        .text {
          text-align: center;
          color: #E6D3E3;
          font-size: 12px;
          font-family: 'PingFang Regular';
          font-style: normal;
          font-weight: 400;
          line-height: 1.5;
          word-wrap: break-word;
          width: 100%;
        }
      }

      .chat {
        margin-left: 10px;
        align-self: flex-start;
        min-width: 164px;
        max-width: calc(100% - 20px);
        min-height: 37px;
        height: auto;
        padding: 10px;
        background: linear-gradient(90deg, rgba(40, 4, 58, 0.40) 0%, rgba(9, 4, 58, 0.40) 100%);
        border-radius: 6px;
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        display: inline-flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-top: 10px;

        span {
          color: white;
          font-size: 12px;
          font-family: 'PingFang Regular';
          font-style: normal;
          font-weight: 400;
          word-wrap: break-word;
          max-width: 100%;
          line-height: 1.5;
          word-break: break-word;
          white-space: pre-wrap;
        }
      }
    }
  }

  .update {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #C5C5D1;
    font-size: 14px;
    font-weight: 400;
    word-wrap: break-word;
    text-align: center;
    z-index: 9999;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    margin-top: 12px;
  }

  .bomBtn {
    width: 100%;
    height: 48px;
    background: #000000;
    border-radius: 8px;
    color: #FFFFFF;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 20px;

    &.disabled {
      background: #cccccc;
      cursor: not-allowed;
      opacity: 0.7;
    }
  }
}
