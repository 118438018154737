.vip {
    width: 100%;
    min-height: 100vh;
    background: #000000;
    position: relative;
    overflow: hidden;
    height: 100vh;
    overflow: hidden;
    color: rgba(255, 255, 255, 0.8);

    .gradient-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        &::before {
            content: '';
            position: absolute;
            width: 60vw;
            height: 60vw;
            background: linear-gradient(180deg, rgba(211, 45, 159, 0.69) 0%, rgba(97, 49, 251, 0.69) 100%);
            border-radius: 50%;
            filter: blur(26vw);
            right: 10.4vw;
            top: 2.71vh;
        }

        &::after {
            content: '';
            position: absolute;
            width: 85vw;
            height: 85vw;
            background: linear-gradient(180deg, rgba(211, 45, 159, 0.69) 0%, rgba(97, 49, 251, 0.69) 100%);
            border-radius: 50%;
            filter: blur(26vw);
            left: -42.13vw;
            bottom: 4.31vh;
        }
    }

    .navbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        background: transparent;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 100;

        .back {
            padding: 8px;
            cursor: pointer;

            img {
                width: 16px;
                height: 16px;
            }
        }

        .title {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            font-size: 18px;
            font-weight: 500;
            color: #fff;
        }
    }

    .vipMax {
        position: relative;
        z-index: 2;
        margin: 60px 16px 15px;
        height: 100px;
        
        .vipBox {
            position: relative;
            z-index: 2;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 25px 20px;

            .left {
                display: flex;

                .vipIconBox {
                    .Icon {
                        margin-right: 10px;
                    }
                }

                .text {
                    .name {
                        color: #fff;
                        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                        font-family: 'PingFang SC';
                        font-size: 16px;
                        font-weight: 400;
                    }

                    .num {
                        color: rgba(255, 255, 255, 0.6);
                        font-family: 'PingFang SC';
                        font-size: 12px;
                        font-weight: 400;
                        margin-top: 4px;
                    }
                }
            }

            .right {
                .lijixufei {
                    color: #fff;
                    font-size: 14px;
                    cursor: pointer;
                    background: linear-gradient(90deg, #D32D9F 0%, #6131FB 100%);
                    padding: 6px 12px;
                    border-radius: 15px;

                    &:active {
                        opacity: 0.9;
                    }
                }
            }
        }

        .vipBg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            pointer-events: none;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 10px;
            }
        }
    }

    .mainBox {
        margin: -40px -20px 0;
        position: relative;
        z-index: 5;
        background: url('../img/aboutBg.svg') no-repeat;
        background-size: 100% auto;
        background-position: top center;
        padding: 40px 16px 20px;
        min-height: calc(100vh - 60px);

        .title {
            text-align: center;
            margin-bottom: 10px;
            img {
                max-width: 200px;
            }
        }

        .cardBox {
            padding: 0 16px;
            height: calc(100% - 150px);
            display: flex;
            flex-direction: column;
            justify-content: center;
            
            .card {
                padding: 15px 20px;
                margin: 10px auto;
                border-radius: 8px;
                box-sizing: border-box;
                width: 100%;
                background-size: cover !important;
                background-position: center !important;

                .name {
                    font-size: 15px;
                    font-weight: bold;
                    background: linear-gradient(90deg, #9d82ff 0%, #d12ba1 100%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }

                .desc {
                    font-size: 12px;
                    color: #b3b3b3;
                    margin-top: 10px;
                    font-family: 'PingFang SC';
                    font-weight: 400;
                    line-height: 1.4;
                }
            }

            .card1 {
                background: url('../img/Vip1.png') no-repeat;
            }

            .card2 {
                background: url('../img/Vip2.png') no-repeat;
            }

            .card3 {
                background: url('../img/Vip3.png') no-repeat;
            }
        }

        .btn {
            background: url('../img/vipBtn.svg') no-repeat;
            background-size: cover;
            background-position: center;
            width: 90%;
            height: 45px;
            position: fixed;
            bottom: 50px;
            left: 50%;
            transform: translate(-50%);
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;

            .price {
                margin-left: 15px;
                color: #fff;
                font-family: 'PingFang SC';
                font-size: 18px;
                font-weight: 400;
                line-height: 42px;
            }

            .unit {
                margin-right: 40px;
                color: #fff;
                font-weight: 600;
            }
        }
    }
}
