.register {
  width: 100%;
  height: 100vh;
  background: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  color: rgba(255, 255, 255, 0.8);

  &::before {
    content: '';
    position: absolute;
    width: 60vw;
    height: 60vw;
    background: linear-gradient(180deg, rgba(211, 45, 159, 0.69) 0%, rgba(97, 49, 251, 0.69) 100%);
    border-radius: 50%;
    filter: blur(26vw);
    right: 10.4vw;
    top: 2.71vh;
  }

  &::after {
    content: '';
    position: absolute;
    width: 85vw;
    height: 85vw;
    background: linear-gradient(180deg, rgba(211, 45, 159, 0.69) 0%, rgba(97, 49, 251, 0.69) 100%);
    border-radius: 50%;
    filter: blur(26vw);
    left: -42.13vw;
    bottom: 4.31vh;
  }

  .right-top-ellipse {
    position: fixed;
    left: 61.067vw;
    top: -12.8vw;
    z-index: 1;
    pointer-events: none;
    width: 69.067vw;
    height: 68.8vw;
    filter: blur(44.48vw);  
  }

  .center {
    z-index: 1;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 350px;
    padding: 35px 17.5px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(30, 30, 30, 0.1);
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    border-radius: 13px;
    box-shadow: 
      0 8px 16px rgba(0, 0, 0, 0.08),
      0 4px 8px rgba(0, 0, 0, 0.06),
      inset 0 -1px 0 rgba(0, 0, 0, 0.1);

    .imgTop {
      text-align: center;
      margin-bottom: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .top-img {
        width: 64px;
        height: 64px;
        margin-bottom: 6px;
      }

      .but-img {
        width: 180px;
        height: auto;
        margin-bottom: 8px;
      }

      .title {
        text-align: center;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        -webkit-text-stroke-width: 1;
        -webkit-text-stroke-color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: #fff;
        margin-bottom: 12px;
      }
    }

    form {
      width: 100%;
      padding: 0;

      .form-item {
        margin-bottom: 16px;

        &:last-of-type {
          margin-bottom: 32px;
        }

        label {
          display: block;
          color: #fff;
          margin-bottom: 8px;
          font-size: 15px;
        }

        .custom-input {
          width: 100%;
          height: 44px;
          background-color: #563481;
          border: none;
          border-radius: 8px;
          padding: 0 16px;
          color: #fff;
          font-size: 15px;
          box-sizing: border-box;

          &::placeholder {
            color: rgba(255, 255, 255, 0.5);
          }

          &:focus {
            outline: none;
            box-shadow: 0 0 0 2px rgba(94, 50, 254, 0.3);
          }

          &:disabled {
            opacity: 0.7;
            cursor: not-allowed;
          }
        }

        .verify-code-box {
          display: flex;
          gap: 10px;
          width: 100%;

          .custom-input {
            flex: 1;
          }

          .verify-code-button {
            height: 44px;
            padding: 0 15px;
            border: none;
            border-radius: 8px;
            background: #A75FB4;
            color: white;
            font-size: 14px;
            cursor: pointer;
            white-space: nowrap;
            transition: opacity 0.3s;

            &:disabled {
              opacity: 0.7;
              cursor: not-allowed;
            }

            &:hover:not(:disabled) {
              opacity: 0.9;
            }
          }
        }
      }

      .btnBox {
        margin-top: 40px;
        margin-bottom: 16px;

        .gradient-button {
          width: 100%;
          height: 44px;
          border: none;
          border-radius: 22px;
          background: linear-gradient(90deg, #5E32FE 0%, #D92D9B 100%);
          color: #fff;
          font-size: 16px;
          cursor: pointer;
          transition: opacity 0.3s;

          &:disabled {
            opacity: 0.7;
            cursor: not-allowed;
          }

          &:hover:not(:disabled) {
            opacity: 0.9;
          }
        }
      }

      .error {
        color: #ff4d4f;
        font-size: 14px;
        text-align: center;
        margin-top: 16px;
      }

      .info {
        color: #52c41a;
        text-align: center;
        margin-top: 16px;
        font-size: 14px;
      }
    }

    .desc {
      margin-top: 24px;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.8);

      a {
        color: #A75FB4;
        text-decoration: none;
        margin-left: 5px;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    p {
      color: rgba(255, 255, 255, 0.8);
      font-size: 14px;
      margin-top: 20px;

      .link {
        color: #D92D9B;
        text-decoration: none;
        font-weight: bold;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
