.searchAva {
  height: 100vh;
  width: 100vw;
  background: #000000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  color: #fff;

  &::before {
    content: '';
    position: absolute;
    width: 60vw;
    height: 60vw;
    background: linear-gradient(180deg, rgba(211, 45, 159, 0.69) 0%, rgba(97, 49, 251, 0.69) 100%);
    border-radius: 50%;
    filter: blur(26vw);
    right: 10.4vw;
    top: 2.71vh;
    z-index: 0;
  }

  &::after {
    content: '';
    position: absolute;
    width: 85vw;
    height: 85vw;
    background: linear-gradient(180deg, rgba(211, 45, 159, 0.69) 0%, rgba(97, 49, 251, 0.69) 100%);
    border-radius: 50%;
    filter: blur(26vw);
    left: -42.13vw;
    bottom: 4.31vh;
    z-index: 0;
  }

  .header {
    height: 54px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    position: relative;
    z-index: 1;

    .back {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-right: 12px;
      
      img {
        width: 18px;
        height: 18px;
      }
    }

    .search-container {
      flex: 1;
      
      form {
        width: 100%;
      }

      .search-box {
        display: flex;
        align-items: center;
        background: #251E2F;
        border-radius: 8px;
        padding: 8px 12px;
        
        input {
          flex: 1;
          background: transparent;
          border: none;
          color: #fff;
          font-size: 14px;
          outline: none;
          
          &::placeholder {
            color: rgba(255, 255, 255, 0.5);
          }
        }

        button {
          background: transparent;
          border: none;
          padding: 0;
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          img {
            width: 18px;
            height: 18px;
            background: linear-gradient(90deg, #dd2898 0%, #5eb9f8 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
    }
  }

  .chat-list {
    flex: 1;
    overflow-y: auto;
    padding: 0 16px;
    position: relative;
    z-index: 1;

    .item {
      padding: 12px 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      cursor: pointer;

      &:last-child {
        border-bottom: none;
      }

      .item-content {
        display: flex;
        align-items: center;

        .photo {
          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
          }
        }

        .msg-box {
          margin-left: 12px;
          flex: 1;

          .name {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .name-text {
              color: #C5C5D1;
              font-family: 'PingFang SC';
              font-size: 15px;
              font-weight: 400;
            }

            .status {
              font-size: 13px;
              color: rgba(255, 255, 255, 0.5);

              .connected {
                background: linear-gradient(90deg, #dd2898 0%, #5eb9f8 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }
            }
          }

          .msg {
            margin-top: 6px;
            color: #686872;
            font-family: 'PingFang SC';
            font-size: 12px;
            font-weight: 400;
          }
        }
      }

      &:hover {
        background: rgba(255, 255, 255, 0.05);
      }
    }
  }
}
