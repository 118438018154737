.roleDetail {
  width: 100%;
  height: 100vh;
  background-color: #0f0f1a;
  color: #fff;
  position: relative;

  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 54px;
    z-index: 999;
    display: flex;
    align-items: center;
    padding: 0 16px;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0) 100%
    );

    .back {
      width: 18px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .title {
      flex: 1;
      text-align: center;
      font-size: 17px;
      color: #fff;
      margin-right: 44px;
    }
  }

  .scroll-view {
    height: 100vh;
    overflow-y: auto;
    position: relative;
    scrollbar-width: none !important; /* Firefox */
    -ms-overflow-style: none !important; /* IE and Edge */
    &::-webkit-scrollbar {
      width: 0 !important;
      display: none !important; /* Chrome, Safari, Opera */
    }
    &::-webkit-scrollbar-thumb {
      background-color: transparent !important;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent !important;
    }

    .bg-video {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 69.3%;
      overflow: hidden;

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .ava-profile {
      position: absolute;
      left: 4.3vw;
      top: 0;
      z-index: 10;
      display: flex;
      align-items: center;
      gap: 2.5vw;
      transform: translateY(-50%);

      .avatar {
        width: 74px;
        height: 74px;
        border-radius: 50%;
        margin-top: 25px;

        background: linear-gradient(180deg, #dd2898 0%, #5eb9f8 100%);
        padding: 2px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .info {
        margin-top: 50px;

        .name {
          text-align: left;
          color: white;
          font-size: max(14px, 1.2vw);
          font-family: "PingFang SC";
          font-weight: 400;
          word-wrap: break-word;
          margin-bottom: 4px;
        }

        .creator {
          color: rgba(255, 255, 255, 0.5);
          font-size: max(10px, 1vw);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          span {
            color: #ff69b4;
            font-family: "PingFang Medium";
          }
        }
      }
    }

    .profile-content {
      z-index: 2;
      width: 100vw;
      height: 60vh;
      background: linear-gradient(180deg, rgba(97, 33, 122, 0.8) 0%, rgba(42, 11, 59, 0.75) 100%);
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
      padding: 30px 20px 0;
      position: absolute;
      bottom: 0;
      

      .tabs-section {
        padding: 0;
        margin-top: 36px; //calc(70px + 23px);
        height: calc(100% - 36px);

        .tabs {
          display: flex;
          padding-left: 0;
          width: 160px;
          height: 32px;
          position: relative;

          .tab {
            position: absolute;
            color: #686872;
            font-size: 15px;
            font-family: "PingFang Medium";
            font-weight: 400;
            word-wrap: break-word;
            cursor: pointer;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            padding-bottom: 6px;

            &:first-child {
              left: 0;
              top: 0;
              width: 80px;
              height: 32px;

              &.active {
                color: white;
                font-size: 20px;
                font-family: "PingFang Heavy";
                word-wrap: break-word;
                padding-bottom: 4px;

                &::after {
                  content: "";
                  position: absolute;
                  width: 12px;
                  height: 4px;
                  bottom: -2px;
                  left: 50%;
                  transform: translateX(-50%);
                  z-index: 2;
                  background: url("../img/sliding_block.svg") no-repeat
                    center/cover;
                }
              }

              &:not(.active) {
                color: #686872;
                font-size: 15px;
                font-family: "PingFang Medium";
              }
            }

            &:last-child {
              left: 80px;
              top: 0;
              width: 80px;
              height: 32px;

              &.active {
                color: white;
                font-size: 20px;
                font-family: "PingFang Heavy";
                word-wrap: break-word;
                padding-bottom: 4px;

                &::after {
                  content: "";
                  position: absolute;
                  width: 12px;
                  height: 4px;
                  bottom: -2px;
                  left: 50%;
                  transform: translateX(-50%);
                  background: url("../img/sliding_block.svg") no-repeat
                    center/cover;
                }
              }

              &:not(.active) {
                color: #686872;
                font-size: 15px;
                font-family: "PingFang Medium";
              }
            }
          }
        }

        .tab-content {
          height: calc(100% - 32px);
          overflow-y: auto;
          scrollbar-width: none;
          padding-bottom: calc(5.05vh + 64px);
          .intro {
            color: #b3b3b3;
            font-size: 13px;
            margin-top: 20px;
            padding-bottom: 120px;

            .intro-text {
              overflow-y: auto;
              scrollbar-width: none; /* Firefox */
              -ms-overflow-style: none; /* IE and Edge */
              white-space: pre-wrap; /* 允许文本换行 */
              word-wrap: break-word; /* 确保长单词也能换行 */
              overflow-x: hidden; /* 防止水平滚动 */
              
              &::-webkit-scrollbar {
                display: none; /* Chrome, Safari, Opera */
                width: 0 !important;
                height: 0 !important; /* 确保横向滚动条也被隐藏 */
              }
            }
          }

          .theater {
            // padding-bottom: 120px;

            .item {
              margin-top: 20px;
              padding: 10px;
              border-radius: 10px;
              background-color: rgba(30, 30, 30, 0.3);

              .title {
                color: #fff;
                font-size: 15px;
                font-weight: bold;
              }

              .time {
                font-size: 12px;
                color: #b3b3b3;
                margin: 10px 0;
                display: flex;
                justify-content: space-between;
              }

              .fm {
                position: relative;
                width: calc(100% - 32px);
                aspect-ratio: 3/4;
                border-radius: 12px;
                overflow: hidden;
                margin: 0 16px;

                video {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }

                .xihuan {
                  color: #fff;
                  position: absolute;
                  bottom: 10px;
                  left: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  .xihuanaixin {
                    margin-right: 10px;
                    width: 30px;
                    height: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    img {
                      display: block;
                      width: 15px;
                      height: 15px;
                    }

                    .aixintubiao {
                      color: rgba(255, 255, 255, 0.8);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .gradient-button {
    position: fixed;
    bottom: 5.05%;
    left: 6.93%;
    right: 6.93%;
    width: 86.13334%;
    transform: none;
    height: 44px;
    border: none;
    border-radius: 22px;
    background: linear-gradient(90deg, #5e32fe 0%, #d92d9b 100%);
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: opacity 0.3s;
    padding: 0;
    z-index: 10;
    white-space: nowrap;

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }

    &:hover:not(:disabled) {
      opacity: 0.9;
    }
  }
}

@font-face {
  font-family: "PingFang Medium";
  src: url("../../public/fonts/PingFang Medium.ttf") format("truetype");
}
