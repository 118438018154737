.feedback {
  position: absolute;
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(180deg, #111118 0%, #38084f 100%);
  color: #fff;

  .navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 44px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    background: transparent;
    z-index: 100;

    .back-button {
      background: none;
      border: none;
      padding: 8px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 16px;
        height: 16px;
      }
    }

    span {
      color: #fff;
      font-size: 16px;
      margin-left: 8px;
    }
  }

  .formBox {
    padding: 60px 16px 0;

    .fankui {
      margin-bottom: 12px;
      font-size: 14px;

      .required {
        color: #ff4d4f;
        margin-right: 4px;
      }
    }

    .fankuiyu {
      margin-bottom: 20px;

      textarea {
        width: 100%;
        height: 150px;
        background: rgba(255, 255, 255, 0.1);
        border: 1px solid rgba(255, 255, 255, 0.2);
        color: #fff;
        padding: 12px;
        font-size: 14px;
        resize: none;
        border-radius: 4px;

        &::placeholder {
          color: rgba(255, 255, 255, 0.5);
        }

        &:focus {
          outline: none;
          border-color: rgba(255, 255, 255, 0.3);
        }
      }
    }

    .uploading {
      margin-bottom: 12px;
      font-size: 14px;
    }

    .tupian {
      margin-bottom: 20px;

      img {
        cursor: pointer;
        border-radius: 4px;
      }

      .jiahao {
        width: 68px;
        height: 68px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(255, 255, 255, 0.1);
        border: 1px solid rgba(255, 255, 255, 0.2);
        cursor: pointer;
        border-radius: 4px;

        .plus-icon {
          width: 32px;
          height: 32px;
          fill: #c5c5d1;
        }

        &:hover {
          background: rgba(255, 255, 255, 0.15);
        }
      }
    }

    .lianxi {
      margin-bottom: 12px;
      font-size: 14px;
    }

    .xuantian {
      margin-bottom: 20px;

      input {
        width: 100%;
        height: 40px;
        background: rgba(255, 255, 255, 0.1);
        border: 1px solid rgba(255, 255, 255, 0.2);
        color: #fff;
        padding: 0 12px;
        font-size: 14px;
        border-radius: 4px;

        &::placeholder {
          color: rgba(255, 255, 255, 0.5);
        }

        &:focus {
          outline: none;
          border-color: rgba(255, 255, 255, 0.3);
        }
      }
    }
  }

  .btn {
    margin: 30px 16px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    background: linear-gradient(270deg, #7000FF 0%, #B100FF 100%);
    border-radius: 22px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;

    &:active {
      opacity: 0.8;
    }
  }

  .dibu {
    padding: 0 16px;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;

    .xiaohongshu {
      margin-top: 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        color: #fff;
        margin-right: 8px;
      }

      .copy-button {
        background: none;
        border: none;
        padding: 4px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        .copy-icon {
          width: 18px;
          height: 18px;
          fill: rgba(255, 255, 255, 0.5);
        }

        &:hover .copy-icon {
          fill: #fff;
        }
      }
    }
  }
}

// Toast Styles
// .toast {
//   position: fixed;
//   top: 16px;
//   left: 50%;
//   transform: translateX(-50%) translateY(-100%);
//   background: rgba(0, 0, 0, 0.8);
//   color: #fff;
//   padding: 12px 24px;
//   border-radius: 4px;
//   font-size: 14px;
//   z-index: 1000;
//   opacity: 0;
//   transition: all 0.3s ease;

//   &.show {
//     transform: translateX(-50%) translateY(0);
//     opacity: 1;
//   }

//   &.toast-success {
//     background: rgba(0, 0, 0, 0.8);
//   }

//   &.toast-error {
//     background: rgba(255, 77, 79, 0.9);
//   }
// }
