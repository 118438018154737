.my {
    width: 100%;
    min-height: 100vh;
    background: #000000;
    position: relative;
    overflow: hidden;
    color: rgba(255, 255, 255, 0.8);
    padding-bottom: 60px;

    .gradient-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: 1;

        &::before {
            content: '';
            position: absolute;
            width: 60vw;
            height: 60vw;
            background: linear-gradient(180deg, rgba(211, 45, 159, 0.69) 0%, rgba(97, 49, 251, 0.69) 100%);
            border-radius: 50%;
            filter: blur(26vw);
            right: 10.4vw;
            top: 2.71vh;
        }

        &::after {
            content: '';
            position: absolute;
            width: 85vw;
            height: 85vw;
            background: linear-gradient(180deg, rgba(211, 45, 159, 0.69) 0%, rgba(97, 49, 251, 0.69) 100%);
            border-radius: 50%;
            filter: blur(26vw);
            left: -42.13vw;
            bottom: 4.31vh;
        }
    }

    .content {
        position: relative;
        z-index: 2;
        padding: 20px 20px 0;
        height: 100%;

        .topbu {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
            color: #fff;
            font-size: 13px;
            z-index: 2;
            line-height: 20px;

            .yiian {
                cursor: pointer;
                opacity: 0.8;
            }
        }

        .userInfo {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            z-index: 2;

            .leftBox {
                display: flex;
                align-items: center;
                gap: 15px;

                .avator {
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .InfoBox {
                    .name {
                        font-size: 18px;
                        font-weight: bold;
                        margin-bottom: 5px;
                    }

                    .edit {
                        color: rgba(255, 255, 255, 0.8);
                        font-size: 14px;
                        cursor: pointer;
                        display: inline-flex;
                        align-items: center;
                    }
                }
            }

            .rightBox {
                cursor: pointer;
            }
        }

        .vipMax {
            position: relative;
            z-index: 2;
            margin: 15px 0 0;
            height: 120px;
            border-radius: 10px;
            overflow: hidden;
            color: #fff;

            .vipBox {
                position: relative;
                z-index: 2;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 20px;
                width: 100%;
                box-sizing: border-box;

                .left {
                    display: flex;
                    align-items: center;
                    gap: 15px;

                    .vipIconBox {
                        .Icon {
                            width: 30px;
                            height: 30px;
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                            }
                        }
                    }

                    .text {
                        .name {
                            font-size: 18px;
                            font-weight: bold;
                            margin-bottom: 5px;
                            color: #fff;
                        }

                        .num {
                            font-size: 14px;
                            color: rgba(255, 255, 255, 0.8);
                        }
                    }
                }

                .right {
                    position: relative;
                    z-index: 2;

                    .lijixufei {
                        padding: 8px 20px;
                        background: rgba(255, 255, 255, 0.2);
                        border-radius: 20px;
                        font-size: 14px;
                        cursor: pointer;
                        color: #fff;
                        transition: all 0.3s ease;
                        position: relative;
                        z-index: 3;
                        display: inline-block;
                        white-space: nowrap;

                        &::before {
                            content: '';
                            position: absolute;
                            top: -16px;
                            right: -16px;
                            bottom: -16px;
                            left: -16px;
                            cursor: pointer;
                            z-index: 1;
                        }

                        &:hover {
                            background: rgba(255, 255, 255, 0.3);
                        }
                    }
                }
            }

            .vipBg {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
                pointer-events: none;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 10px;
                }
            }
        }

        .aboutBg {
            height: calc(100vh - 278px);
            margin: -44px -20px 0;
            position: relative;
            z-index: 2;
            pointer-events: none;
            width: 100vw;
            left: 0;
            overflow: hidden;
            background: url('../img/aboutBg.svg');
            background-repeat: no-repeat;
            background-position: top center;
            background-size: 100%;
            img {
                width: 100%;
                display: block;
                position: absolute;
                left: 0;
                top: 0;
            }

            .mainBox {
                pointer-events: auto;
                padding: 24px 16px 16px;
                height: calc(100% - 22px);
                overflow-y: auto;
                scrollbar-width: none;
                margin-top: 22px;
                .money {
                    position: relative;
                    z-index: 2;
                    background: linear-gradient(180deg, rgba(199.75, 116.88, 255, 0.20) 0%, rgba(119.85, 70.13, 153, 0.20) 100%);
                    border-radius: 12px;
                    padding: 16px 20px 20px;
                    overflow: hidden;
                    height: 132px;

                    .moneyBg {
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 100%;
                        height: 100%;
                        pointer-events: none;
                        z-index: 1;
                        opacity: 0.5;
                        mix-blend-mode: overlay;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    .money1 {
                        position: relative;
                        z-index: 2;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 14px;

                        .title {
                            display: flex;
                            align-items: center;
                            gap: 8px;

                            img {
                                width: 24px;
                                height: 24px;
                            }

                            span {
                                font-size: 16px;
                                font-weight: 600;
                            }
                        }

                        > div:last-child {
                            font-size: 18px;
                            font-weight: 600;
                        }
                    }

                    .desc {
                        position: relative;
                        z-index: 2;
                        color: rgba(255, 255, 255, 0.8);
                        font-size: 12px;
                        line-height: 1.6;

                        p {
                            margin: 4px 0;

                            span {
                                color: #FF69B4;
                                margin: 0 2px;
                            }

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }

                .theater {
                    margin-top: 20px;

                    .title {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-start;
                        margin-bottom: 20px;
                        font-size: 18px;
                        font-weight: bold;
                        z-index: 2;
                        position: relative;

                        div:first-child {
                            width: 72px;
                            margin-left: 5px;
                        }

                        .sliding-block-wrapper {
                            position: absolute;
                            margin-top: 28px;
                            margin-left: 35px;

                            .sliding-block {
                                width: 12px;
                                height: 4px;
                            }
                        }
                    }

                    .itemBox {
                        background: rgb(27, 20, 54);
                        border-radius: 12px;
                        margin-bottom: 20px;
                        padding-bottom: 18px;
                        z-index: 2;

                        .title {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-bottom: 10px;
                            padding: 15px 15px 0;

                            div:first-child {
                                flex: 1;
                                margin-right: 15px;
                                color: white;
                                font-size: 18px;
                                font-family: 'PingFang SC';
                                font-weight: 400;
                                word-wrap: break-word;
                                min-height: 36px;
                            }

                            .fenxiang {
                                padding: 5px 15px;
                                background: rgba(255, 255, 255, 0.2);
                                border-radius: 15px;
                                font-size: 14px;
                                cursor: pointer;
                            }

                            i {
                                margin-left: 10px;
                                cursor: pointer;
                                opacity: 0.8;
                            }
                        }

                        .time {
                            display: flex;
                            justify-content: space-between;
                            color: rgba(255, 255, 255, 0.8);
                            font-size: 14px;
                            margin-bottom: 10px;
                            padding: 0 20px;

                            span {
                                color: #FF69B4;
                            }
                        }

                        .bgc {
                            width: calc(100% - 32px);
                            aspect-ratio: 3/4;
                            border-radius: 12px;
                            overflow: hidden;
                            margin: 0 16px;

                            video {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }
                }
            }
        }
    }

    .icon-btn {
        color: #A39FA7;
        padding: 6px;
        border-radius: 50%;
        line-height: 1;
        display: inline-flex;
        font-style: normal;
        align-items: center;
        :hover {
            color: #fff;
            background: rgba(255, 255, 255, 0.2);
        }
    }

    .input-box {
        height: 36px;
        background-color: #563481;
        border: none;
        border-radius: 8px;
        outline: none;
        display: inline-flex;
        align-items: center;
        padding: 0;
        padding: 0;
        margin: 0 !important;
        width: 100% !important;
        input {
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0.05);
            border: none;
            color: #fff;
            padding: 12px;
            font-size: 14px;
            resize: none;
            border-radius: 4px 0 0 4px;
            &::placeholder {
                color: rgba(255, 255, 255, 0.6);
            }

            &:focus {
                outline: none;
                box-shadow: 0 0 0 2px rgba(94, 50, 254, 0.3);
            }
        }
        button {
            flex: none;
            height: 100%;
            padding: 0px 16px;
            background: transparent;
            outline: none;
            border: none;
            color: #fff;
            font-size: 14px;
        }
    }

    .title-label {
    }

    .loading-indicator {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 20px 0;
        text-align: center;
        
        .loading-text {
          color: rgba(255, 255, 255, 0.7);
          font-size: 14px;
          padding: 8px 15px;
          border-radius: 20px;
          background: rgba(120, 70, 153, 0.2);
          display: inline-block;
        }
    }

    .loading-indicator.top {
        padding: 10px 0;
        .loading-text {
            background: rgba(255, 255, 255, 0.1);
            color: rgba(255, 255, 255, 0.9);
        }
    }

    .theater {
        .title {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 8px;
        }

        .sliding-block-wrapper {
            display: flex;
            justify-content: center;

            .sliding-block {
                width: 12px;
                height: 4px;
            }
        }

        .itemBox {
            margin-bottom: 20px;

            .title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;
                font-size: 16px;

                .fenxiang {
                    padding: 5px 15px;
                    background: rgba(255, 255, 255, 0.2);
                    border-radius: 15px;
                    font-size: 14px;
                    cursor: pointer;
                }

                i {
                    margin-left: 10px;
                    cursor: pointer;
                    opacity: 0.8;
                }
            }

            .time {
                display: flex;
                justify-content: space-between;
                color: rgba(255, 255, 255, 0.8);
                font-size: 14px;
                margin-bottom: 10px;

                span {
                    color: #FF69B4;
                }
            }

            .bgc {
                width: calc(100% - 32px);
                aspect-ratio: 3/4;
                border-radius: 12px;
                overflow: hidden;
                margin: 0 16px;

                video {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}
