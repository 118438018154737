.pay-page {
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(to bottom, rgba(#111118, 0.8) 0%, #38084F 100%);
    color: #C5C5D1;
    padding: 90px 16px 15px;
    position: relative;
    .gradient-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        &::before {
            content: "";
            position: absolute;
            width: 258px;
            height: 258px;
            background: linear-gradient(180deg, #D32D9F 0%, #6131FB 100%);
            border-radius: 50%;
            filter: blur(26vw);
            right: -100px;
            top: -100px;
        }

        &::after {
            content: "";
            position: absolute;
            width: 85vw;
            height: 85vw;
            background: linear-gradient(180deg, rgba(211, 45, 159, 0.69) 0%, rgba(97, 49, 251, 0.69) 100%);
            border-radius: 50%;
            filter: blur(26vw);
            left: -42.13vw;
            bottom: 4.31vh;
        }
    }
    .navbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        background: transparent;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 100;

        .back {
            padding: 8px;
            cursor: pointer;

            img {
                width: 16px;
                height: 16px;
            }
        }

        .title {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            font-size: 18px;
            font-weight: 500;
            color: #fff;
        }
    }
    .pay-type-wrap {
        position: relative;
        z-index: 2;
        margin: 0;
        background: rgba($color: #161622, $alpha: 0.6);
        width: 100%;
        border-radius: 12px;
        padding: 24px 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .pay-money {
            margin-top: 0;
            margin-bottom: 30px;
            font-size: 70px;
            color: #fff;
            font-weight: bold;
        }
        .pay-money-label {
            font-weight: 500;
        }
        .pay-money-unit {
            font-size: 26px;
        }
        .pay-type-select-label {
            width: 100%;
            text-align: left;
            font-weight: bold;
        }
        .pay-type-radio-group {
            display: inline-flex;
            align-items: center;
            justify-content: space-between;
        }
        .select-pay-item {
            display: inline-flex;
            align-items: center;
            &.checked {
                .pay-type-radio {
                    background-color: #D72D9C;
                    border-color: #D72D9C;
                }
                .right-icon {
                    opacity: 1;
                    transform: scale(1);
                }
            }
        }
        .pay-type-radio-group {
            width: 100%;
            display: inline-flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 16px;
        }
        .pay-type-radio {
            border-radius: 50%;
            border: 1px solid #C5C5D1;
            width: 16px;
            height: 16px;
            flex: none;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            input {
                display: none;
            }
            .right-icon {
                opacity: 1;
                transform: scale(0);
                transition: all linear 200ms;
            }
        }
        .pay-type-img {
            height: 24px;
            width: 24px;
            margin-left: 6px;
        }
        .pay-type-label {
            margin-left: 6px;
        }
    }

    .qcode-view {
        position: relative;
        z-index: 2;
        margin-top: 16px;
        margin-bottom: 32px;
        background: rgba(22, 22, 34, 0.6);
        width: 100%;
        border-radius: 12px;
        padding: 30px 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .qcode {
            min-height: 207px;
            min-width: 207px;
            border-radius: 16px;
            border: 1px solid #7631EB;
            background-color: #161622;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 20px;
        }
        .qcode-tip {
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            .number {
                margin: 4px;
                color: #D92D9B;
                font-size: 26px;
                font-weight: bold;
            }
            .tip-text-1 {
                margin-top: 24px;
            }
            .tip-text-strong {
                margin-top: 12px;
                color: #D72D9C;
            }
        }
    }
}

.pay-result-pop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($color: #000000, $alpha: 0.6);
    backdrop-filter: blur(2px);
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    .pay-result-content {
        width: 340px;
        padding: 20px;
        background: linear-gradient(to bottom, rgba(#22005C, 1) 0%, #4C0082 100%);
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 16px;
    }

    .pay-result-message {
        padding: 12px 0;
        color: #fff;
    }

    .pay-result-footer {
        display: inline-flex;
        width: 100%;
        margin-top: 24px;
        .btn {
            border-radius: 8px;
            flex: 1;
            width: 100%;
            outline: none;
            color: #fff;
            height: 46px;
            text-align: center;
            min-width: 88px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            box-shadow: none;
            border: 1px solid transparent;
            font-size: 16px;
            &+.btn {
                margin-left: 16px;
            }
        }

        .cancel-btn {
            background-color: #4D14AB;
            border-color: #4D14AB;
        }

        .ok-btn {
            background-color: #A114AB;
            border-color: #A114AB;
        }
    }
}